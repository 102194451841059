import './Dashboard.css';
import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';
import { Link } from "react-router-dom";

import NavBar from './navigation/NavBar';
import { getClubStats, getClubPutts, getProfile } from '../actions/AppActions';
import { green } from '../constants/Colors';
import { API_URL } from '../constants/Strings';
import SideBar from './navigation/SideBar';
import PuttrSpinner from './common/PuttrSpinner';
import { getToken } from './common/GetToken';

function DashboardScreen(props){

  const [clubStats, setClubStats] = useState(null);
  const [clubName, setClubName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [stripeCustomer, setStripeCustomer] = useState(null);
  const [createError, setCreateError] = useState(null);
  const [clubs, setClubs] = useState(null);

  useEffect(() => {

    getData();

  },[]);
  const getData = async() => {

    if (props.app.clubId){
      let url = API_URL + '/club/analytics/stats/' + props.app.clubId;

    	axios.get(url)
      .then(request => {
        setClubStats(request.data);
      })
      .catch(function (error) {
        console.log("Fetch error:", error);
      });
    }
    else {

      const tok = await getToken();
      getSubscriptions(tok);
      getClubs();

    }
  }
  const getSubscriptions = (tok) => {
    let url = API_URL + '/web/getStripeCustomer';
    const opts = { headers: { Authorization: `Bearer ${tok}`} };

    axios.get(url, opts)
    .then(request => {
      setStripeCustomer(request.data);
    })
    .catch(function (error) {
      console.log("Create stripe user error:", error);
    });
  }
  const getClubs = () => {
    let url = API_URL + '/admin/clubs';

    axios.get(url)
    .then(request => {
      setClubs(request.data);
    })
    .catch(function (error) {
      console.log("Get clubs error:", error);
    });
  }
  const renderStat = (num, text) => {
    return(
      <div style={{padding:'5px', alignItems:'center'}}>
        <div className="gameScore">{num.toLocaleString()}</div>
        <div className="description">{text}</div>
      </div>
    );
  }
  const renderGames = () => {

    if (clubStats){

      return clubStats.gamesPlayed.map((item, index) => {

        if (index < 10){

          return (

            <div key={index} style={{marginTop:'20px', alignItems:'center'}} className="prow">

              {renderStat(item.total,item.title)}

            </div>
          );

        }

      });

    }
  }
  const renderHomeStats = () => {

    if (clubStats){

      console.log("showing");

      return (

        <div style={{display: 'grid', placeItems: 'center'}}>

          <h4>LAST 30 DAYS</h4>

          <div style={{marginTop:'20px', alignItems:'center'}} className="prow">

            {renderStat(clubStats.putts,"PUTTS")}
            {renderStat(clubStats.games,"GAMES")}

          </div>

          <div style={{marginTop:'10px', alignItems:'center'}} className="prow">

            {renderGames()}

          </div>

        </div>

      );

    }

    else {
      return(
        <div>

          <div className="label">LOADING STATS...</div>

          <Spinner animation="border" variant="success" />

        </div>
      );
    }

  }
  const renderLeaderboard = () => {

    const l = "/leaderboard/" + props.app.clubId;

    const regUrl = "https://app.puttr.co/leaderboard/" + props.app.clubId;// + "/0";
    const qrPath = "https://api.qrserver.com/v1/create-qr-code/?data="+regUrl+"&amp;size=100x100"

    return(

      <div style={{marginTop:10, display: 'grid', placeItems: 'center'}}>

        <div>
          <a href={l} className="link" target="_blank">VIEW LEADERBOARD</a>
        </div>
        <div style={{alignItems:'center', marginTop:10}}>
          <img src={qrPath} width="100" height="100" alt="QR Code" />
        </div>
      </div>
    );
  }
  const renderGraph = () => {

    if (props.app.clubPutts){

      console.log("showing graph");

      //const data = props.app.clubPutts;

      return (
        <div style={{marginTop:20}}>

          <div>LAST 30 DAYS</div>

          <LineChart width={600} height={300} data={props.app.clubPutts}>
            <Line type="monotone" dataKey="count" stroke="#8884d8" />
            <XAxis dataKey="index"  />
            <YAxis />

          </LineChart>

        </div>
      );

    }

    else {
      return(
        <div>

          <div className="label">LOADING PUTTS...</div>

          <Spinner animation="border" variant="success" />

        </div>
      );
    }
  }

  const renderCreateButton = () => {
    if (isLoading){
      return(

        <div style={{width:'100px', alignItems:'center'}}>
          <Spinner animation="border" variant="success" />
        </div>
      );
    }

    else {
      return(
        <Button variant="success" onClick={createClub}>CREATE CLUB</Button>
      );
    }
  }

  const createClub = async() => {

    setIsLoading(true);

    if (clubName == ""){
      setCreateError("You must enter a club name");
      setIsLoading(false);
    }
    else {//not blank
      //convert to uppercase
      let cn = clubName.toUpperCase();
      setClubName(cn);

      const re = /^[A-Za-z]+$/;
      if (re.test(cn)){
        let exists = false;
        for (let i=0; i< clubs.length;i++){
          if (cn == clubs[i].clubId){
            exists = true;
            break;
          }
        }
        if (exists){
          setCreateError("This club name already exists, please try another name");
          setIsLoading(false);
        }
        else {
          console.log("WOO HOO, CREATE A CLUB with ", cn);
          setCreateError(null);
          const tok = await getToken();
          const url = API_URL + '/club/create';
          const opts = { headers: { Authorization: `Bearer ${tok}`} };
          const params = { clubId: cn };
          axios.post(url, params, opts)
          .then(function (response) {
            setIsLoading(false);
            props.getProfile(tok);
          })
          .catch(function (error) {
            console.log(error);
          });
        }
      }
      else {
        setCreateError("Club names can only have letters, not numbers, spaces or special characters");
        setIsLoading(false);
      }

    }

  }
  const renderError = () => {
    if (createError){
      return(
        <div style={{marginTop:10}}>
          <Alert variant="danger">{createError}</Alert>
        </div>
      );
    }
  }

  const renderCreateClub = () => {

    if (!props.app.profile.isClubAdmin){//not admins

      if (stripeCustomer){//check if subscriptions

        console.log("stripe:", stripeCustomer);

        if (stripeCustomer.subscriptions.data.length){

          return(

            <div style={{marginTop:10, display: 'grid', placeItems: 'center'}}>

              <div className="label">ENTER YOUR DESIRED CLUB NAME</div>

              <Form.Group className="mb-3">

                <Form.Control type="text" placeholder="CLUB_NAME" value={clubName} onChange={e => setClubName(e.target.value)} />

              </Form.Group>

              {renderCreateButton()}

              {renderError()}

            </div>

          );

        }
        else {

          return(

            <div style={{marginTop:10, display: 'grid', placeItems: 'center'}}>

              <h3>NO CURRENT SUBSCRIPTIONS</h3>
              <h5>You must have an active subscription to create a club.</h5>
              <Link to="/subscription" className="link">VIEW SUBSCRIPTIONS</Link>

            </div>
          );

        }

      }

      else {
        return(
          <div className="label">LOADING SUBSCRIPTION INFO</div>
        );
      }

    }
  }
  const renderHome = () => {

    if (props.app.profile){

      if (props.app.profile.isClubAdmin){

        if (clubStats){

          return(

            <div style={{ display: 'flex', height: '100%' }}>

              <Container fluid className="align-items-center justify-content-center">

                <div style={{marginTop:20, display: 'grid', placeItems: 'center'}}>

                  <h1>CLUB DASHBOARD</h1>

                </div>

                <div style={{ display: 'grid', placeItems: 'center'}}>

                  {renderHomeStats()}

                </div>

                <div style={{ display: 'grid', placeItems: 'center'}}>

                  {renderLeaderboard()}

                </div>

              </Container>

            </div>

          );

        }

        else {
          return(
            <PuttrSpinner />
          );
        }

      }

      else {

        return(

          <div style={{ display: 'flex', height: '100%' }}>

            <Container fluid className="align-items-center justify-content-center">

              <div style={{marginTop:10, display: 'grid', placeItems: 'center'}}>

                <h1>DASHBOARD</h1>

                {renderCreateClub()}

              </div>

            </Container>

          </div>

        );
      }

    }

    else {

      return(
        <PuttrSpinner />
      );

    }

  }
  return(

    <Container fluid className="align-items-center justify-content-center">

      <NavBar />

      <div style={{display: 'grid', placeItems: 'center'}}>

        {renderHome()}

      </div>

      <SideBar />

    </Container>

  );
}

const mapStateToProps = state => {
  return {
    app: state.app
  }
};

export default connect(mapStateToProps, {getClubStats, getClubPutts, getProfile})(DashboardScreen);
