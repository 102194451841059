import React, {useState, useEffect} from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faFile, faChartLine, faCog, faSignOutAlt, faCopyright, faUserGroup, faUser, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { getAuth, signOut } from "firebase/auth";

import { lightGreen } from '../../constants/Colors';


export default function SignOut(props) {

  useEffect(() => {

    logoutUser();


  },[]);

  const logoutUser = () => {

    const auth = getAuth();
    signOut(auth).then(() => {
      console.log("signed out");
      
    }).catch((error) => {
      console.log("error signing out");
    });

  }



  return (

    <div >

      <div>SIGNING OUT...</div>

    </div>

  );
}

const mapStateToProps = state => {
  return {
    app: state.app
  }
};
